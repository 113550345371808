/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        siRecommends
        editors
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const getAreaOfInterest = /* GraphQL */ `
  query GetAreaOfInterest($id: ID!) {
    getAreaOfInterest(id: $id) {
      id
      name
    }
  }
`;
export const listAreaOfInterests = /* GraphQL */ `
  query ListAreaOfInterests(
    $filter: ModelAreaOfInterestFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAreaOfInterests(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const listContacts = /* GraphQL */ `
  query ListContacts(
    $filter: ModelContactFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listContacts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      nextToken
    }
  }
`;
export const getContact = /* GraphQL */ `
  query GetContact($id: ID!) {
    getContact(id: $id) {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const getEventType = /* GraphQL */ `
  query GetEventType($id: ID!) {
    getEventType(id: $id) {
      id
      name
    }
  }
`;
export const listEventTypes = /* GraphQL */ `
  query ListEventTypes(
    $filter: ModelEventTypeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventTypes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
      }
      nextToken
    }
  }
`;
export const getEventVenue = /* GraphQL */ `
  query GetEventVenue($id: ID!) {
    getEventVenue(id: $id) {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        siRecommends
        editors
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const listEventVenues = /* GraphQL */ `
  query ListEventVenues(
    $filter: ModelEventVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEventVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        eventId
        venueId
        event {
          id
          title
          summary
          description
          timeStart
          timeEnd
          audience
          cost
          price
          contactId
          type
          faculty
          school
          faculty2
          school2
          faculty3
          school3
          siRecommends
          editors
          areaOfInterest
          moreInfo
          owner
          published
          createdAt
        }
        venue {
          id
          name
          code
          location
        }
      }
      nextToken
    }
  }
`;
export const listVenues = /* GraphQL */ `
  query ListVenues(
    $filter: ModelVenueFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listVenues(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
      nextToken
    }
  }
`;
export const getVenue = /* GraphQL */ `
  query GetVenue($id: ID!) {
    getVenue(id: $id) {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const getProducer = /* GraphQL */ `
  query GetProducer($id: ID!) {
    getProducer(id: $id) {
      id
      faculty
      school
    }
  }
`;
export const listProducers = /* GraphQL */ `
  query ListProducers(
    $filter: ModelProducerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProducers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        faculty
        school
      }
      nextToken
    }
  }
`;
