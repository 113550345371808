import React from 'react';
// import { S3Image } from 'aws-amplify-react';
import { Image } from '@aws-amplify/ui-react'
import { StorageImage } from '@aws-amplify/ui-react-storage';
import '@aws-amplify/ui-react/styles.css';
import { Drawer, Divider, Row, Col, Button, Tag, Space, Badge } from 'antd';
import {
  MailTwoTone,
  CalendarTwoTone,
  DollarCircleTwoTone,
  PhoneTwoTone,
  HomeTwoTone,
  EnvironmentTwoTone,
  MessageTwoTone,
  TagTwoTone,
} from '@ant-design/icons';
import { formatEventTime, formatCreatedAtTime } from '../utils/index';
import { Link } from 'react-router-dom';
import DOMPurify from 'dompurify';

const DescriptionItem = ({ title, content }) => (
  <div className='site-description-item-profile-wrapper'>
    <span className='site-description-item-profile-p-label'>{title}</span>
    {content}
  </div>
);

const EventDrawer = ({
  eventDetails,
  onClose,
  isShowing,
  handlePublishClick,
}) => {
  const {
    id,
    contact,
    description,
    moreInfo,
    published,
    image,
    title,
    areaOfInterest,
    audience,
    timeStart,
    timeEnd,
    faculty,
    school,
    faculty2,
    school2,
    faculty3,
    school3,
    venues,
    siRecommends,
  } = eventDetails;

  let contact_name = '';
  if (contact) {
    contact_name = `${contact.firstName} ${contact.lastName}`;
    if (contact.title) {
      contact_name = contact.title + ' ' + contact_name;
    }
  }
  const contact_address = contact && contact.address;
  const contact_telephone = contact && contact.telephone;
  const contact_email = contact && contact.email;

  const descriptionSafeHTML = DOMPurify.sanitize(description, {
    ALLOWED_TAGS: ['b', 'strong', 'u', 'i', 'p', 'br', 'em', 'img'],
  });

  let moreInfoLink = 'More information';
  if (moreInfo) {
    moreInfoLink = (
      <a href={moreInfo} target='_blank' rel='noreferrer'>
        More information
      </a>
    );
  }

  return (
    <Drawer
      width={960}
      placement='right'
      // closable={false}
      title='Event Detail'
      onClose={onClose}
      visible={isShowing}
      bodyStyle={{ paddingBottom: 80 }}
      footer={
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
          }}
        >
          <Button danger onClick={handlePublishClick}>
            {published ? 'Unpublish' : 'Publish'}
          </Button>
          <Space>
            <Link to={`/events/${id}/edit`}>
              <Button onClick={onClose}>Edit</Button>
            </Link>
            <Button onClick={onClose} type='primary'>
              Close
            </Button>
          </Space>
        </div>
      }
    >
      <Row>
        <Col span={6} className='m-3'>
          {image && (
            <StorageImage
              imgKey={image.key}
              style={{ maxWidth: '100%', maxHeight: '100%', paddingRight: '1.2em' }}
            />
          )}
        </Col>
        <Col span={18}>
          <Row>
            <Col span={24}>
              <div className='event-title'>
                {title}&nbsp;&nbsp;
                {published ? (
                  <Tag color='#87d068'>Published</Tag>
                ) : (
                  <Tag color='warning'>Draft</Tag>
                )}
                {audience === 'staff' ? (
                  <Tag color='#f50'>Staff Only Event</Tag>
                ) : (
                  <Tag color='#87d068'>Public Event</Tag>
                )}
                {siRecommends && (
                  <Tag color='#87d068'>SI Recommends</Tag>
                )}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <div className='site-description-item-profile-wrapper'>
                {areaOfInterest &&
                  areaOfInterest.map((area) => (
                    <Badge
                      key={area}
                      count={area}
                      style={{
                        backgroundColor: '#1890ff',
                        marginRight: 8,
                        fontSize: '0.75em',
                      }}
                    />
                  ))}
              </div>
            </Col>
          </Row>

          <Row>
            <Col span={24}>
              <DescriptionItem
                title={<CalendarTwoTone />}
                content={`${timeStart && formatEventTime(timeStart)} - ${timeEnd && formatEventTime(timeEnd)
                  }`}
              />
            </Col>
          </Row>
          <Row>
            <Col span={1}>
              <TagTwoTone />
            </Col>
            <Col span={23}>
              <div className='site-description-item-profile-wrapper'>
                <p>{school ? `${faculty} / ${school}` : faculty}</p>
                {faculty2 && school2 ? (<p>{faculty2} / {school2}</p>) :
                  faculty2 ? (<p>{faculty2}</p>) : <></>}
                {faculty3 && school3 ? (<p>{faculty3} / {school3}</p>) :
                  faculty3 ? (<p>{faculty3}</p>) : <></>}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={1}>
              <EnvironmentTwoTone />
            </Col>
            <Col span={23}>
              <div className='site-description-item-profile-wrapper'>
                {venues &&
                  venues.items &&
                  venues.items.map((item) => (
                    <Tag key={item.venue.name}>{item.venue.name}</Tag>
                  ))}
              </div>
            </Col>
          </Row>
          <Row>
            <Col span={1}>
              <DollarCircleTwoTone />
            </Col>
            <Col span={23}>
              <div
                className='site-description-item-profile-wrapper'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {eventDetails.cost === 'free' ? 'Free' : eventDetails.price}
              </div>
            </Col>
          </Row>
        </Col>
      </Row>
      <Divider />
      <Row>
        {eventDetails.summary && (
          <>
            <Col span={24}>
              <div
                className='site-description-item-profile-wrapper'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {eventDetails.summary}
              </div>
            </Col>
            <Divider />
          </>
        )}
        <Col span={24}>
          <div
            className='site-description-item-profile-wrapper drawer-description'
            style={{ whiteSpace: 'pre-wrap' }}
            dangerouslySetInnerHTML={{ __html: descriptionSafeHTML }}
          ></div>
        </Col>
        {eventDetails.moreInfo && (
          <>
            <Divider />
            <Col span={24}>
              <div
                className='site-description-item-profile-wrapper'
                style={{ whiteSpace: 'pre-wrap' }}
              >
                {moreInfoLink}
              </div>
            </Col>
          </>
        )}
      </Row>
      <Divider />
      <p className='site-description-item-profile-p'>Contact</p>
      <Row>
        <Col span={12}>
          <DescriptionItem title={<MessageTwoTone />} content={contact_name} />
        </Col>
        <Col span={12}>
          <DescriptionItem
            title={<PhoneTwoTone />}
            content={contact_telephone}
          />
        </Col>
      </Row>
      <Row>
        <Col span={12}>
          <DescriptionItem title={<MailTwoTone />} content={contact_email} />
        </Col>
        <Col span={12}>
          <DescriptionItem title={<HomeTwoTone />} content={contact_address} />
        </Col>
      </Row>
      <Divider />
      <p className='site-description-item-profile-p'>Author</p>
      <Row>
        <Col span={24} style={{ color: 'rgba(0, 0, 0, 0.65)' }}>
          Created by{' '}
          {eventDetails.owner &&
            eventDetails.owner.replace(
              `${process.env.REACT_APP_IDP}_`,
              ''
            )}{' '}
          {eventDetails.createdAt &&
            formatCreatedAtTime(eventDetails.createdAt)}
        </Col>
      </Row>
    </Drawer>
  );
};

export default EventDrawer;
