import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Auth, Hub } from 'aws-amplify';
import { Layout } from 'antd';
import Navbar from './components/layout/Navbar';
import Home from './pages/Home';
import EventPage from './pages/EventPage';
import Lookup from './pages/Lookup';
import {
  Authenticator,
  Button,
} from '@aws-amplify/ui-react';
import EventState from './context/event/EventState';
import './App.css';
import bg from './images/bg.jpeg';

const { Header, Content, Footer } = Layout;

class App extends Component {
  _isMounted = false;
  state = {
    user: null,
  };

  async componentDidMount() {
    // console.log(AmplifySignIn.federated)
    this._isMounted = true;
    // this.getUserData();
    Hub.listen('auth', this.onHubCapsule);

    // if the URL contains ?identity_provider=x, and the user is signed out, we redirect to the IdP on load
    const urlParams = new URLSearchParams(window.location.search);
    const idpParamName = 'identity_provider';
    const idp = urlParams.get(idpParamName);

    try {
      // remove identity_provider query param (not needed if signed in successfully)
      if (idp) {
        urlParams.delete(idpParamName);
        const params = urlParams.toString();
        window.history.replaceState(
          null,
          null,
          window.location.pathname + (params ? '?' + params : '')
        );
      }
      this.getUserData();
    } catch (e) {
      // user is not authenticated, and we have an IdP in the request
      if (e === 'not authenticated' && idp) {
        await Auth.federatedSignIn({ customProvider: idp });
      } else {
        console.warn(e);
        this.setState({ user: null });
      }
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  getUserData = async () => {
    try {
      const user = await Auth.currentAuthenticatedUser();
      if (this._isMounted) {
        this.setState({ user });
      }
    } catch (err) {
      if (this._isMounted) {
        this.setState({ user: null });
      }
    }
  };

  onHubCapsule = (capsule) => {
    // console.log("CAPSULE", capsule)
    switch (capsule.payload.event) {
      case 'signIn':
        this.getUserData();
        // console.log('Sign In')
        break;
      case 'signUp':
        // console.log('Sign Up')
        break;
      case 'signOut':
        // console.log('Sign Out');
        this.setState({ user: null });
        break;
      case 'cognitoHostedUI':
        this.getUserData();
        // console.log('Sign In from UI')
        // workaround for FF bug: https://bugzilla.mozilla.org/show_bug.cgi?id=1422334
        // eslint-disable-next-line
        // noinspection SillyAssignmentJS
        // window.location.hash = window.location.hash;
        break;
      case 'cognitoHostedUI_failure':
        // console.log('Sign Out from UI');
        this.setState({ user: null });
        break;
      default:
        return;
    }
  };

  handleSignout = async () => {
    try {
      // console.log('Signing out');
      await Auth.signOut();
    } catch (err) {
      console.error('Error signing out user', err);
    }
  };



  render() {
    const { user } = this.state;
    const loginWidget = (
      <div
        className='federated-button-only'
        style={{
          backgroundImage: `url(${bg})`,
          height: '100vh',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          backgroundPosition: '30% 70%',
        }}
      >
        <p>
          To access what's on admin interface, please login with your Unikey
          and password
        </p>
        <Button
          onClick={() =>
            Auth.federatedSignIn({
              customProvider: window.location.origin.includes('test') ? '' : process.env.REACT_APP_IDP,
            })
          }
        >
          Login
        </Button>
      </div>
    );

    return !user ? (
      loginWidget
    ) : (
      <EventState>
        <Router>
          <Layout>
            <Header>
              <Navbar handleSignout={this.handleSignout} />
            </Header>
            <Content>
              <Switch>
                <Route
                  path='/addsingle'
                  exact
                  component={() => <EventPage mode='add' />}
                />
                <Route
                  path='/addmulti'
                  exact
                  component={() => <EventPage mode='multi' />}
                />
                <Route
                  path='/events/:eventid/edit'
                  component={({ match }) => (
                    <EventPage mode='edit' eventId={match.params.eventid} />
                  )}
                />
                <Route
                  path='/lookups/:lookupname'
                  component={({ match }) => (
                    <Lookup name={match.params.lookupname} />
                  )}
                />
                <Route path='/' component={Home} />
              </Switch>
            </Content>
            <Footer style={{ textAlign: 'center' }}>
              Whats On Admin Interface © 2021 The University of Sydney
              <br />
              Please direct all enquiries regarding this calendar to{' '}
              <a href='mailto:events.calendaradmin@sydney.edu.au'>
                events.calendaradmin@sydney.edu.au
              </a>
            </Footer>
          </Layout>
        </Router>
      </EventState>
    );
  }
}

export default App;
