import React, { useState, useContext, useEffect } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { Link } from 'react-router-dom';
import { deleteEvent } from '../graphql/mutations';
import {
  updateEvent,
  deleteEventVenue,
  createEvent,
  createEventVenue,
} from '../graphql/mutations';
import { Table, Space, Button, notification, Tag, Row } from 'antd';
import DeletePopover from '../components/DeletePopover';
import {
  EditTwoTone,
  CloseCircleTwoTone,
  CheckCircleTwoTone,
  DeleteFilled,
  CopyTwoTone,
  EyeTwoTone,
} from '@ant-design/icons';
import { formatEventTime } from '../utils/index';
import EventDrawer from '../components/EventDrawer';
import DuplicatePopover from '../components/DuplicatePopover';
import EventContext from '../context/event/eventContext';
import parseISO from 'date-fns/parseISO';
import SearchBar from '../components/layout/SearchBar';

import DownloadCSV from '../components/DownloadCSV';
import { Flex } from '@aws-amplify/ui-react';

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      summary
      description
      createdAt
      timeStart
      timeEnd
      cost
      price
      audience
      venues {
        items {
          id
          venue {
            id
            name
          }
        }
        # nextToken
      }
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      moreInfo
      owner
      published
      image {
        key
        region
        bucket
      }
    }
  }
`;

const Home = () => {
  const eventContext = useContext(EventContext);
  const { events, isAdmin } = eventContext;
  const [displayDrawer, setDisplayDrawer] = useState(false);
  const [eventDetails, setEventDetails] = useState({});
  const [filteredEvents, setFilteredEvents] = useState([]);

  useEffect(() => {
    // console.log(events)
    setFilteredEvents(events);
  }, [events]);

  const eventColumns = [
    {
      title: 'TITLE',
      dataIndex: 'title',
      key: 'title',
      render: (text, record) => (
        <span
          onClick={() => onSelectEvent(record)}
          style={{ cursor: 'pointer' }}
        >
          {record.title}{' '}
          {record.audience === 'staff' ? (
            <Tag
              color='#f50'
              style={{
                fontSize: '80%',
                lineHeight: '16px',
              }}
            >
              Staff
            </Tag>
          ) : (
            ''
          )}
        </span>
      ),
      sorter: (a, b) => a.title.localeCompare(b.title),
    },
    {
      title: 'PUBLISHED',
      dataIndex: 'published',
      key: 'published',
      width: '20px',
    },
    {
      title: 'PRODUCER',
      dataIndex: 'producer',
      key: 'producer'
    },
    {
      title: 'START DATETIME',
      dataIndex: 'timeStart',
      key: 'timeStart',
      sorter: (a, b) =>
        parseISO(a.timeStartDateTime) - parseISO(b.timeStartDateTime),
      defaultSortOrder: 'descend',
    },
    {
      title: 'END DATETIME',
      dataIndex: 'timeEnd',
      key: 'timeEnd',
      sorter: (a, b) =>
        parseISO(a.timeEndDateTime) - parseISO(b.timeEndDateTime),
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <Space size='small'>
          <Button
            shape='round'
            size='small'
            icon={<EyeTwoTone />}
            onClick={() => onSelectEvent(record)}
          >
            View
          </Button>

          <Link to={`/events/${record.id}/edit`}>
            <Button shape='round' size='small' icon={<EditTwoTone />}>
              Edit
            </Button>
          </Link>

          <DeletePopover deleteHandler={handleDeleteEvent} record={record}>
            <Button shape='round' size='small' danger icon={<DeleteFilled />}>
              Delete
            </Button>
          </DeletePopover>

          <DuplicatePopover
            duplicateHandler={handleDuplicateEvent}
            record={record}
          >
            <Button shape='round' size='small' icon={<CopyTwoTone />}>
              Duplicate
            </Button>
          </DuplicatePopover>
        </Space>
      ),
      responsive: ['sm'],
    },
    {
      title: 'ACTION',
      key: 'action',
      render: (text, record) => (
        <div>
          <div className='div-sm'>
            <Button
              shape='round'
              size='small'
              icon={<EyeTwoTone />}
              onClick={() => onSelectEvent(record)}
            >
              View
            </Button>
          </div>
          <div className='div-sm'>
            <Link to={`/events/${record.id}/edit`}>
              <Button shape='round' size='small' icon={<EditTwoTone />}>
                Edit
              </Button>
            </Link>
          </div>
          <div className='div-sm'>
            <DeletePopover deleteHandler={handleDeleteEvent} record={record}>
              <Button shape='round' size='small' danger icon={<DeleteFilled />}>
                Delete
              </Button>
            </DeletePopover>
          </div>
          <div className='div-sm'>
            <DuplicatePopover
              duplicateHandler={handleDuplicateEvent}
              record={record}
            >
              <Button shape='round' size='small' icon={<CopyTwoTone />}>
                Duplicate
              </Button>
            </DuplicatePopover>
          </div>
        </div>
      ),
      responsive: ['xs'],
    },
  ];

  const handleDeleteEvent = async (id) => {
    try {
      const input = { id };
      const result = await API.graphql(graphqlOperation(getEvent, input));
      //Delete Event Venues
      const eventVenues = result.data.getEvent.venues.items;
      // console.log(eventVenues)
      if (eventVenues && eventVenues.length > 0) {
        eventVenues.forEach((v) => {
          // console.log('Deleting event venue', v.id)
          const venueInput = {
            id: v.id,
          };
          API.graphql(
            graphqlOperation(deleteEventVenue, { input: venueInput })
          );
        });
      }
      await API.graphql(graphqlOperation(deleteEvent, { input }));
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Event has been deleted successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDuplicateEvent = async (id) => {
    // console.log('Duplicating event', id)
    try {
      const input = { id };
      const result = await API.graphql(graphqlOperation(getEvent, input));
      const existingEvent = result.data.getEvent;
      // console.log('Existing Event', existingEvent);

      const user = await Auth.currentAuthenticatedUser();

      const newEvent = {
        areaOfInterest: existingEvent.areaOfInterest,
        audience: existingEvent.audience,
        contactId: existingEvent.contactId,
        cost: existingEvent.cost,
        price: existingEvent.price,
        description: existingEvent.description,
        moreInfo: existingEvent.moreInfo,
        faculty: existingEvent.faculty,
        school: existingEvent.school,
        faculty2: existingEvent.faculty2,
        school2: existingEvent.school2,
        faculty3: existingEvent.faculty3,
        school3: existingEvent.school3,
        published: false,
        summary: existingEvent.summary,
        timeStart: existingEvent.timeStart,
        timeEnd: existingEvent.timeEnd,
        title: 'Copy of ' + existingEvent.title,
        type: existingEvent.type,
        image: {
          key: existingEvent.image.key,
          bucket: existingEvent.image.bucket,
          region: existingEvent.image.region,
        },
        owner: user.username,
      };
      // console.log("NEW Event info", newEvent);

      const newResult = await API.graphql(
        graphqlOperation(createEvent, { input: newEvent })
      );
      // console.log("Event info", result)

      const eventId = newResult.data.createEvent.id;

      existingEvent.venues.items.length > 0 &&
        existingEvent.venues.items.forEach(async (venue) => {
          const venueInput = {
            eventId,
            venueId: venue.venue.id,
          };
          // console.log("VENUE INPUT", venueInput)
          await API.graphql(
            graphqlOperation(createEventVenue, {
              input: venueInput,
            })
          );
        });

      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Event has been duplicated successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const onClose = () => setDisplayDrawer(false);

  const getEventDetails = async (id) => {
    // console.log(id)
    const input = { id };
    const res = await API.graphql(graphqlOperation(getEvent, input));
    const result = res.data.getEvent;
    // console.log("get event detail", result)
    setEventDetails(result);
  };

  const onSelectEvent = (record) => {
    getEventDetails(record.id);
    setDisplayDrawer(true);
  };

  const handlePublishClick = async () => {
    // console.log("Publish/Unpublish")
    const input = {
      id: eventDetails.id,
      published: !eventDetails.published,
    };
    await API.graphql(graphqlOperation(updateEvent, { input }));
    setDisplayDrawer(false);
  };

  const dataSource = filteredEvents.map((item) => {
    return {
      id: item.id,
      title: item.title,
      audience: item.audience,
      timeStart: formatEventTime(item.timeStart),
      timeStartDateTime: item.timeStart,
      timeEnd: formatEventTime(item.timeEnd),
      timeEndDateTime: item.timeEnd,
      published: item.published ? (
        <CheckCircleTwoTone
          twoToneColor='#52c41a'
          style={{ fontSize: '16px' }}
        />
      ) : (
        <CloseCircleTwoTone twoToneColor='red' style={{ fontSize: '16px' }} />
      ),
      key: item.id,
      producer: item.school ? `${item.faculty} / ${item.school}` : item.faculty,
    };
  });

  const handleTitleSearch = (searchText) => {
    const filteredEvents = events.filter(({ title, faculty, faculty2, faculty3, school, school2, school3 }) => {
      title = title.toLowerCase();
      faculty = faculty ? faculty.toLowerCase() : '';
      faculty2 = faculty2 ? faculty2.toLowerCase() : '';
      faculty3 = faculty3 ? faculty3.toLowerCase() : '';
      school = school ? school.toLowerCase() : '';
      school2 = school2 ? school2.toLowerCase() : '';
      school3 = school3 ? school3.toLowerCase() : '';

      const lowerCaseSearchText = searchText.toLowerCase();
      return (title.includes(lowerCaseSearchText) ||
        faculty.includes(lowerCaseSearchText) ||
        faculty2.includes(lowerCaseSearchText) ||
        faculty3.includes(lowerCaseSearchText) ||
        school.includes(lowerCaseSearchText) ||
        school2.includes(lowerCaseSearchText) ||
        school3.includes(lowerCaseSearchText)
      );
    });

    setFilteredEvents(filteredEvents);
  };

  // console.log(events);
  // console.log(eventContext);

  const handleProducerSearch = async (searchText) => {
    // console.log('searchText: ' + searchText);
    const eventIdsToDisplay = [];

    for (const event of events) {
      // console.log(event);
      const eventDetails = await API.graphql(graphqlOperation(getEvent, event.id));
      // console.log(eventDetails);
      if (eventDetails.producer || eventDetails.school) {
        if (
          eventDetails.producer.toLowerCase().includes(searchText.toLowerCase()) ||
          eventDetails.school.toLowerCase().includes(searchText.toLowerCase())
        ) {
          eventIdsToDisplay.push(eventDetails.id);
        }
      }
    }

    if (eventIdsToDisplay.length > 0) {
      const filteredEvents = events.filter((event) =>
        eventIdsToDisplay.includes(event.id)
      );
      setFilteredEvents(filteredEvents);
    } else {
      console.log('None to display');
      setFilteredEvents([]);
    }
  };



  return (
    <div>
      <div className='search-header'>
        <h1>
          Total events <span className='badge'>{events.length}</span>
        </h1>
        <Flex>
          {isAdmin && (<DownloadCSV />)}
          <SearchBar placeholder="Search event title or producers..." onSearch={handleTitleSearch} className='search-action' />
        </Flex>
      </div>
      {displayDrawer && (
        <EventDrawer
          eventDetails={eventDetails}
          onClose={onClose}
          isShowing={displayDrawer}
          handlePublishClick={handlePublishClick}
        />
      )}
      <Table
        dataSource={dataSource}
        columns={eventColumns}
        pagination={{ pageSize: 100 }}
        size='small'
      />
    </div>
  );
};

export default Home;
