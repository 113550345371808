import React, { useState } from 'react';
import { Drawer, Button, Form, Input, Space, AutoComplete } from 'antd';

const VenueDrawer = ({
  title,
  mode,
  isShowing,
  closeDrawer,
  handleAddVenue,
  handleEditVenue,
  onFinishFailed,
  currentEntry,
  venueList
}) => {

  const [options, setOptions] = useState([])

  const [current, setCurrent] = useState({
    id: currentEntry.id,
    name: currentEntry.name,
    code: currentEntry.code,
    location: currentEntry.location,
  })

  const handleSearch = (value) => {
    setOptions(() => {
      if (!value || value.length < 3) {
        return [];
      }
      return venueList.filter(x => x.name?.toLowerCase().includes(value.toLowerCase()) || x.code?.toLowerCase().includes(value.toLowerCase()))
    })
  };

  const handleChange = (name, value) => {
    handleSearch(value);
    setCurrent({
      ...current,
      [name]: value,
    })
  }

  return (
    <Drawer
      title={`${mode === 'add' ? 'Add' : 'Edit'} ${title}`}
      width={640}
      onClose={closeDrawer}
      visible={isShowing}
      mode={mode}
    >
      <Form
        layout="vertical"
        initialValues={current}
        onFinish={mode === 'add' ? handleAddVenue : handleEditVenue}
        onFinishFailed={onFinishFailed}
      >
        <Form.Item
          name="name"
          label="Name"
          rules={[{ required: true, message: `Please enter ${title} name` }]}
        >
          <Input
            placeholder={`Please enter ${title} name`}
            value={current.name}
            onChange={(e) => handleChange('name', e.target.value)}
          />
        </Form.Item>

        <Form.Item
          name="code"
          label="Venue Code"
        >
          <Input
            placeholder={`Please enter ${title} code`}
            value={current.code}
            onChange={(e) => handleChange('code', e.target.value)} />
        </Form.Item>

        <Form.Item
          name="location"
          label="Location"
        >
          <Input
            placeholder={`Please enter ${title} location`}
            value={current.location}
            onChange={(e) => handleChange('location', e.target.value)}
          />
        </Form.Item>

        <Form.Item style={{
          textAlign: 'right',
        }}>
          <Space >
            <Button onClick={closeDrawer}>
              Cancel
            </Button>
            <Button type="primary" htmlType="submit">
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>

      {options.length > 0 ? (
        <div style={{ marginTop: '3rem' }}>
          The venue you are trying to add might already exist:
          {options.map(o => (
            <li key={o.id} style={{ marginLeft: '1em' }}>
              {o.name} {o.code && ` > ${o.code}`} {o.location && ` > ${o.location}`}
            </li>
          ))}
        </div>
      ) : (<></>)}


    </Drawer>
  )
}

export default VenueDrawer