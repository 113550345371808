import React from 'react';
import { Input } from 'antd';

const Search = Input.Search;

const SearchBar = ({ placeholder, onSearch, ...props }) => (
  <div {...props}>
    <Search
      placeholder={placeholder}
      onSearch={onSearch}
      allowClear
      role='search'
      style={{ width: 300 }}
    />
  </div>
);

export default SearchBar;
