import React, { useState, useContext } from 'react';
import { Table, Space, Button, notification } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import { createVenue, updateVenue, deleteVenue } from '../../graphql/mutations';
import { getVenue } from '../../graphql/queries';
import DeletePopover from '../../components/DeletePopover';
import VenueDrawer from './VenueDrawer';
import EventContext from '../../context/event/eventContext';
import {
  EditOutlined,
  DeleteFilled,
  PlusCircleFilled,
} from '@ant-design/icons';

const VenueList = () => {
  const eventContext = useContext(EventContext);
  const { venues } = eventContext;

  const initialVenue = { id: null, name: '', code: '', location: '' };
  const [isShowing, setIsShowing] = useState(false);
  const [mode, setMode] = useState('add');
  const [currentVenue, setCurrentVenue] = useState({ ...initialVenue });

  const venueColumns = [
    {
      title: 'VENUE',
      dataIndex: 'name',
      key: 'name',
      sorter: (a, b) => a.name.localeCompare(b.name),
    },
    {
      title: 'VENUE CODE',
      dataIndex: 'code',
      key: 'code',
      sorter: (a, b) => a.code.localeCompare(b.code),
    },
    {
      title: 'LOCATION',
      dataIndex: 'location',
      key: 'location',
      sorter: (a, b) => a.location.localeCompare(b.location),
    },
    {
      title: 'ACTION',
      key: 'action',
      width: '200px',
      render: (text, record) => (
        <Space size='middle'>
          <Button
            shape='round'
            size='small'
            icon={<EditOutlined />}
            onClick={() => handleEdit(record)}
          >
            Edit
          </Button>
          <DeletePopover deleteHandler={handleDeleteVenue} record={record}>
            <Button shape='round' size='small' danger icon={<DeleteFilled />}>
              Delete
            </Button>
          </DeletePopover>
        </Space>
      ),
    },
  ];

  const handleEdit = (venue) => {
    setMode('edit');
    setCurrentVenue({
      id: venue.id,
      name: venue.name,
      code: venue.code,
      location: venue.location,
    });
    setIsShowing(true);
  };

  const closeDrawer = () => {
    setMode('add');
    setCurrentVenue({ ...initialVenue });
    setIsShowing(false);
  };

  const handleAddVenue = async (values) => {
    // console.log('Add Venue:', values);
    try {
      const input = {
        name: values.name,
        code: values.code,
        location: values.location,
      };
      await API.graphql(graphqlOperation(createVenue, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Venue has been added successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleEditVenue = async (values) => {
    // console.log("Edit Venue", values)
    try {
      const input = {
        id: currentVenue.id,
        name: values.name,
        code: values.code,
        location: values.location,
      };
      await API.graphql(graphqlOperation(updateVenue, { input }));
      closeDrawer();
      notification.success({
        message: 'Success',
        duration: 2,
        description: 'Venue has been updated successfully.',
      });
    } catch (err) {
      console.error(err);
    }
  };

  const handleDeleteVenue = async (id) => {
    try {
      const input = { id };
      const result = await API.graphql(graphqlOperation(getVenue, input));
      console.log(result.data.getVenue.event.items.length);

      if (result.data.getVenue.event.items.length > 0) {
        notification.error({
          message: 'Deletion Failed',
          duration: 4,
          description:
            "Cannot delete this venue because it's currently being used.",
        });
      } else {
        await API.graphql(graphqlOperation(deleteVenue, { input }));
        notification.success({
          message: 'Success',
          duration: 2,
          description: 'Venue has been deleted successfully.',
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const onFinishFailed = (error) => {
    console.error('Failed:', error);
  };

  const dataSource = venues.map((item) => {
    return {
      ...item,
      key: item.id,
    };
  });

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          paddingBottom: '8px',
        }}
      >
        <h2>
          Total number of Venues
          <span className='badge'>{dataSource.length}</span>
        </h2>
        <Button
          shape='round'
          size='small'
          type='primary'
          onClick={() => setIsShowing(true)}
        >
          <PlusCircleFilled /> Add Venue
        </Button>
      </div>

      {isShowing && (
        <VenueDrawer
          title='venue'
          mode={mode}
          isShowing={isShowing}
          closeDrawer={closeDrawer}
          handleAddVenue={handleAddVenue}
          handleEditVenue={handleEditVenue}
          onFinishFailed={onFinishFailed}
          currentEntry={currentVenue}
          venueList={dataSource}
        />
      )}

      <Table
        dataSource={dataSource}
        columns={venueColumns}
        pagination={{ pageSize: 50 }}
        size='small'
      />
    </>
  );
};

export default VenueList;
