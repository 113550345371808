/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ap-southeast-2",
    "aws_appsync_graphqlEndpoint": "https://lrumhdfjdrhczbblmv4bqtcz2i.appsync-api.ap-southeast-2.amazonaws.com/graphql",
    "aws_appsync_region": "ap-southeast-2",
    "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS",
    "aws_appsync_apiKey": "da2-rap4i56gizchvhqbehleijec3y",
    "aws_cognito_identity_pool_id": "ap-southeast-2:a9991e66-84a6-49c8-a88d-5333cb99d4ce",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_lW6wzDLKD",
    "aws_user_pools_web_client_id": "4sdaapuipfce3lbfm7v4oauvuj",
    "oauth": {},
    "aws_cognito_username_attributes": [],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [
        "SMS"
    ],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ],
    "aws_content_delivery_bucket": "eventscalendar-20201117130619-hostingbucket-master",
    "aws_content_delivery_bucket_region": "ap-southeast-2",
    "aws_content_delivery_url": "https://d2sjet3u0zcn83.cloudfront.net",
    "aws_user_files_s3_bucket": "eventscalendar14a645cd782b43058d535c002f33e89005600-master",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsmobile;
