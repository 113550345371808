/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createEvent = /* GraphQL */ `
  mutation CreateEvent(
    $input: CreateEventInput!
    $condition: ModelEventConditionInput
  ) {
    createEvent(input: $input, condition: $condition) {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent(
    $input: UpdateEventInput!
    $condition: ModelEventConditionInput
  ) {
    updateEvent(input: $input, condition: $condition) {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent(
    $input: DeleteEventInput!
    $condition: ModelEventConditionInput
  ) {
    deleteEvent(input: $input, condition: $condition) {
      id
      title
      summary
      description
      timeStart
      timeEnd
      audience
      cost
      price
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      image {
        key
        bucket
        region
      }
      moreInfo
      owner
      published
      createdAt
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      venues {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const createAreaOfInterest = /* GraphQL */ `
  mutation CreateAreaOfInterest(
    $input: CreateAreaOfInterestInput!
    $condition: ModelAreaOfInterestConditionInput
  ) {
    createAreaOfInterest(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const updateAreaOfInterest = /* GraphQL */ `
  mutation UpdateAreaOfInterest(
    $input: UpdateAreaOfInterestInput!
    $condition: ModelAreaOfInterestConditionInput
  ) {
    updateAreaOfInterest(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const deleteAreaOfInterest = /* GraphQL */ `
  mutation DeleteAreaOfInterest(
    $input: DeleteAreaOfInterestInput!
    $condition: ModelAreaOfInterestConditionInput
  ) {
    deleteAreaOfInterest(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const createContact = /* GraphQL */ `
  mutation CreateContact(
    $input: CreateContactInput!
    $condition: ModelContactConditionInput
  ) {
    createContact(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const updateContact = /* GraphQL */ `
  mutation UpdateContact(
    $input: UpdateContactInput!
    $condition: ModelContactConditionInput
  ) {
    updateContact(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const deleteContact = /* GraphQL */ `
  mutation DeleteContact(
    $input: DeleteContactInput!
    $condition: ModelContactConditionInput
  ) {
    deleteContact(input: $input, condition: $condition) {
      id
      title
      firstName
      lastName
      email
      address
      telephone
    }
  }
`;
export const createEventType = /* GraphQL */ `
  mutation CreateEventType(
    $input: CreateEventTypeInput!
    $condition: ModelEventTypeConditionInput
  ) {
    createEventType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const updateEventType = /* GraphQL */ `
  mutation UpdateEventType(
    $input: UpdateEventTypeInput!
    $condition: ModelEventTypeConditionInput
  ) {
    updateEventType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const deleteEventType = /* GraphQL */ `
  mutation DeleteEventType(
    $input: DeleteEventTypeInput!
    $condition: ModelEventTypeConditionInput
  ) {
    deleteEventType(input: $input, condition: $condition) {
      id
      name
    }
  }
`;
export const createEventVenue = /* GraphQL */ `
  mutation CreateEventVenue(
    $input: CreateEventVenueInput!
    $condition: ModelEventVenueConditionInput
  ) {
    createEventVenue(input: $input, condition: $condition) {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        siRecommends
        editors
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const updateEventVenue = /* GraphQL */ `
  mutation UpdateEventVenue(
    $input: UpdateEventVenueInput!
    $condition: ModelEventVenueConditionInput
  ) {
    updateEventVenue(input: $input, condition: $condition) {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        siRecommends
        editors
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const deleteEventVenue = /* GraphQL */ `
  mutation DeleteEventVenue(
    $input: DeleteEventVenueInput!
    $condition: ModelEventVenueConditionInput
  ) {
    deleteEventVenue(input: $input, condition: $condition) {
      id
      eventId
      venueId
      event {
        id
        title
        summary
        description
        timeStart
        timeEnd
        audience
        cost
        price
        contactId
        type
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        siRecommends
        editors
        areaOfInterest
        image {
          key
          bucket
          region
        }
        moreInfo
        owner
        published
        createdAt
        contact {
          id
          title
          firstName
          lastName
          email
          address
          telephone
        }
        venues {
          nextToken
        }
      }
      venue {
        id
        name
        code
        location
        event {
          nextToken
        }
      }
    }
  }
`;
export const createVenue = /* GraphQL */ `
  mutation CreateVenue(
    $input: CreateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    createVenue(input: $input, condition: $condition) {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const updateVenue = /* GraphQL */ `
  mutation UpdateVenue(
    $input: UpdateVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    updateVenue(input: $input, condition: $condition) {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const deleteVenue = /* GraphQL */ `
  mutation DeleteVenue(
    $input: DeleteVenueInput!
    $condition: ModelVenueConditionInput
  ) {
    deleteVenue(input: $input, condition: $condition) {
      id
      name
      code
      location
      event {
        items {
          id
          eventId
          venueId
        }
        nextToken
      }
    }
  }
`;
export const createProducer = /* GraphQL */ `
  mutation CreateProducer(
    $input: CreateProducerInput!
    $condition: ModelProducerConditionInput
  ) {
    createProducer(input: $input, condition: $condition) {
      id
      faculty
      school
    }
  }
`;
export const updateProducer = /* GraphQL */ `
  mutation UpdateProducer(
    $input: UpdateProducerInput!
    $condition: ModelProducerConditionInput
  ) {
    updateProducer(input: $input, condition: $condition) {
      id
      faculty
      school
    }
  }
`;
export const deleteProducer = /* GraphQL */ `
  mutation DeleteProducer(
    $input: DeleteProducerInput!
    $condition: ModelProducerConditionInput
  ) {
    deleteProducer(input: $input, condition: $condition) {
      id
      faculty
      school
    }
  }
`;
