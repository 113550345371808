import React, { useContext, useEffect, useReducer } from 'react';
import { API, graphqlOperation, Auth } from 'aws-amplify';
import { dynamicSort, dynamicSortMultiple } from '../../utils';
import {
  listAreaOfInterests,
  listContacts,
  listEventTypes,
  listVenues,
  listProducers,
} from '../../../src/graphql/queries';

import {
  onCreateAreaOfInterest,
  onUpdateAreaOfInterest,
  onDeleteAreaOfInterest,
  onCreateContact,
  onUpdateContact,
  onDeleteContact,
  onCreateEventType,
  onUpdateEventType,
  onDeleteEventType,
  onCreateVenue,
  onUpdateVenue,
  onDeleteVenue,
  onCreateProducer,
  onUpdateProducer,
  onDeleteProducer,
  onCreateEvent,
  onUpdateEvent,
  onDeleteEvent,
} from '../../../src/graphql/subscriptions';

import {
  SET_USER,
  SET_ADMIN,
  SET_LOADING,
  SET_CONSENT,
  ADD_AREA,
  SET_AREAS,
  UPDATE_AREA,
  DELETE_AREA,
  ADD_CONTACT,
  SET_CONTACTS,
  UPDATE_CONTACT,
  DELETE_CONTACT,
  ADD_EVENT,
  SET_EVENTS,
  UPDATE_EVENT,
  DELETE_EVENT,
  ADD_EVENT_TYPE,
  SET_EVENT_TYPES,
  UPDATE_EVENT_TYPE,
  DELETE_EVENT_TYPE,
  ADD_PRODUCER,
  SET_PRODUCERS,
  UPDATE_PRODUCER,
  DELETE_PRODUCER,
  ADD_VENUE,
  SET_VENUES,
  UPDATE_VENUE,
  DELETE_VENUE,
} from '../type';

import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import EventContext from './eventContext';
import eventReducer from './eventReducer';
import ConsentModal from '../../components/layout/ConsentModal';

const listEvents = /* GraphQL */ `
  query ListEvents(
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        audience
        title
        timeStart
        timeEnd
        published
        faculty
        school
        faculty2
        school2
        faculty3
        school3
        editors
      }
      nextToken
    }
  }
`;

const antIcon = <LoadingOutlined style={{ fontSize: 96 }} spin />;

const EventState = (props) => {

  const initialState = {
    loading: true,
    isAdmin: false,
    user: null,
    consent: false,
    areaOfInterests: [],
    contacts: [],
    events: [],
    eventTypes: [],
    producers: [],
    venues: [],
  };

  const [state, dispatch] = useReducer(eventReducer, initialState);

  useEffect(() => {
    const loadData = async () => {
      try {
        const options = { filter: null, limit: 500 };
        const areas = await API.graphql(
          graphqlOperation(listAreaOfInterests, options)
        );
        dispatch({
          type: SET_AREAS,
          payload: areas.data.listAreaOfInterests.items.sort(
            dynamicSort('name')
          ),
        });

        const contacts = await API.graphql(
          graphqlOperation(listContacts, {
            ...options,
            limit: 1000
          })
        );
        dispatch({
          type: SET_CONTACTS,
          payload: contacts.data.listContacts.items.sort(
            dynamicSortMultiple('firstName', 'lastName')
          ),
        });

        const types = await API.graphql(
          graphqlOperation(listEventTypes, options)
        );
        dispatch({
          type: SET_EVENT_TYPES,
          payload: types.data.listEventTypes.items.sort(dynamicSort('name')),
        });

        const producers = await API.graphql(
          graphqlOperation(listProducers, options)
        );
        dispatch({
          type: SET_PRODUCERS,
          payload: producers.data.listProducers.items.sort(
            dynamicSortMultiple('faculty', 'school')
          ),
        });

        const venues = await API.graphql(graphqlOperation(listVenues, options));
        dispatch({
          type: SET_VENUES,
          payload: venues.data.listVenues.items.sort(dynamicSort('name')),
        });

        const user = await Auth.currentAuthenticatedUser();
        const groups = user.signInUserSession.idToken.payload['cognito:groups'];
        const isAdmin = groups && groups.includes('Admin');
        const username = user.username.replace(`USYDOKTA_`, '');

        let listFilter;
        if (isAdmin) {
          listFilter = null;
        } else {
          listFilter = {
            or: [
              { editors: { contains: username } },
              { owner: { contains: username } },
            ]
          };
        }

        const firstPageOfEvents = await API.graphql(
          graphqlOperation(listEvents, {
            ...options,
            filter: listFilter,
            limit: 100,
            nextToken: undefined,
          })
        );

        let _events = firstPageOfEvents.data.listEvents.items;
        let nextToken = firstPageOfEvents.data.listEvents.nextToken;
        while (nextToken) {
          const nextPageOfEvents = await API.graphql(
            graphqlOperation(listEvents, {
              ...options,
              filter: listFilter,
              limit: 100,
              nextToken,
            })
          );
          _events = _events.concat(nextPageOfEvents.data.listEvents.items);
          nextToken = nextPageOfEvents.data.listEvents.nextToken;
        }

        dispatch({ type: SET_USER, payload: user });

        dispatch({ type: SET_ADMIN, payload: isAdmin });

        let events;
        if (isAdmin) {
          events = _events;
        } else {
          const additionalEvents = _events.filter(e => e?.editors?.includes(username));
          var ids = new Set(_events.map(e => e.id));
          events = [..._events, ...additionalEvents.filter(e => !ids.has(e.id))];
        }
        dispatch({ type: SET_EVENTS, payload: events });

        dispatch({ type: SET_LOADING, payload: false });
      } catch (err) {
        console.error('Error fetching application state', err);
      }
    };

    loadData();

    //Area of interest subscriptions
    const addAreaListener = API.graphql(
      graphqlOperation(onCreateAreaOfInterest)
    ).subscribe({
      next: (areaData) => {
        dispatch({
          type: ADD_AREA,
          payload: areaData.value.data.onCreateAreaOfInterest,
        });
      },
    });

    const updateAreaListener = API.graphql(
      graphqlOperation(onUpdateAreaOfInterest)
    ).subscribe({
      next: (areaData) => {
        dispatch({
          type: UPDATE_AREA,
          payload: areaData.value.data.onUpdateAreaOfInterest,
        });
      },
    });

    const deleteAreaListener = API.graphql(
      graphqlOperation(onDeleteAreaOfInterest)
    ).subscribe({
      next: (areaData) => {
        dispatch({
          type: DELETE_AREA,
          payload: areaData.value.data.onDeleteAreaOfInterest,
        });
      },
    });

    //Contact subscriptions
    const addContactListener = API.graphql(
      graphqlOperation(onCreateContact)
    ).subscribe({
      next: (contactData) => {
        dispatch({
          type: ADD_CONTACT,
          payload: contactData.value.data.onCreateContact,
        });
      },
    });

    const updateContactListener = API.graphql(
      graphqlOperation(onUpdateContact)
    ).subscribe({
      next: (contactData) => {
        dispatch({
          type: UPDATE_CONTACT,
          payload: contactData.value.data.onUpdateContact,
        });
      },
    });

    const deleteContactListener = API.graphql(
      graphqlOperation(onDeleteContact)
    ).subscribe({
      next: (contactData) => {
        dispatch({
          type: DELETE_CONTACT,
          payload: contactData.value.data.onDeleteContact,
        });
      },
    });

    //Event subscriptions
    const addEventListener = API.graphql(
      graphqlOperation(onCreateEvent, { owner: Auth.user.username })
    ).subscribe({
      next: (eventData) => {
        const event = eventData.value.data.onCreateEvent;
        const payload = {
          id: event.id,
          audience: event.audience,
          published: event.published,
          timeStart: event.timeStart,
          timeEnd: event.timeEnd,
          title: event.title,
          faculty: event.faculty,
          school: event.school,
          faculty2: event.faculty2,
          school2: event.school2,
          faculty3: event.faculty3,
          school3: event.school3,
        };
        if (event.owner === Auth.user.username || state.isAdmin) {
          dispatch({
            type: ADD_EVENT,
            payload,
          });
        }
      },
    });

    const updateEventListener = API.graphql(
      graphqlOperation(onUpdateEvent, { owner: Auth.user.username })
    ).subscribe({
      next: (eventData) => {
        const event = eventData.value.data.onUpdateEvent;
        const payload = {
          id: event.id,
          audience: event.audience,
          published: event.published,
          timeStart: event.timeStart,
          timeEnd: event.timeEnd,
          title: event.title,
          faculty: event.faculty,
          school: event.school,
          faculty2: event.faculty2,
          school2: event.school2,
          faculty3: event.faculty3,
          school3: event.school3,
        };
        dispatch({
          type: UPDATE_EVENT,
          payload,
        });
      },
    });

    const deleteEventListener = API.graphql(
      graphqlOperation(onDeleteEvent, { owner: Auth.user.username })
    ).subscribe({
      next: (eventData) => {
        dispatch({
          type: DELETE_EVENT,
          payload: eventData.value.data.onDeleteEvent,
        });
      },
    });

    //Event type subscriptions
    const addEventTypeListener = API.graphql(
      graphqlOperation(onCreateEventType)
    ).subscribe({
      next: (eventTypeData) => {
        dispatch({
          type: ADD_EVENT_TYPE,
          payload: eventTypeData.value.data.onCreateEventType,
        });
      },
    });

    const updateEventTypeListener = API.graphql(
      graphqlOperation(onUpdateEventType)
    ).subscribe({
      next: (eventTypeData) => {
        dispatch({
          type: UPDATE_EVENT_TYPE,
          payload: eventTypeData.value.data.onUpdateEventType,
        });
      },
    });

    const deleteEventTypeListener = API.graphql(
      graphqlOperation(onDeleteEventType)
    ).subscribe({
      next: (eventTypeData) => {
        dispatch({
          type: DELETE_EVENT_TYPE,
          payload: eventTypeData.value.data.onDeleteEventType,
        });
      },
    });

    //Producer subscriptions
    const addProducerListener = API.graphql(
      graphqlOperation(onCreateProducer)
    ).subscribe({
      next: (producerData) => {
        dispatch({
          type: ADD_PRODUCER,
          payload: producerData.value.data.onCreateProducer,
        });
      },
    });

    const updateProducerListener = API.graphql(
      graphqlOperation(onUpdateProducer)
    ).subscribe({
      next: (producerData) => {
        dispatch({
          type: UPDATE_PRODUCER,
          payload: producerData.value.data.onUpdateProducer,
        });
      },
    });

    const deleteProducerListener = API.graphql(
      graphqlOperation(onDeleteProducer)
    ).subscribe({
      next: (producerData) => {
        dispatch({
          type: DELETE_PRODUCER,
          payload: producerData.value.data.onDeleteProducer,
        });
      },
    });

    //Venue subscriptions
    const addVenueListener = API.graphql(
      graphqlOperation(onCreateVenue)
    ).subscribe({
      next: (venueData) => {
        dispatch({
          type: ADD_VENUE,
          payload: venueData.value.data.onCreateVenue,
        });
      },
    });

    const updateVenueListener = API.graphql(
      graphqlOperation(onUpdateVenue)
    ).subscribe({
      next: (venueData) => {
        dispatch({
          type: UPDATE_VENUE,
          payload: venueData.value.data.onUpdateVenue,
        });
      },
    });

    const deleteVenueListener = API.graphql(
      graphqlOperation(onDeleteVenue)
    ).subscribe({
      next: (venueData) => {
        dispatch({
          type: DELETE_VENUE,
          payload: venueData.value.data.onDeleteVenue,
        });
      },
    });

    return () => {
      addAreaListener.unsubscribe();
      updateAreaListener.unsubscribe();
      deleteAreaListener.unsubscribe();

      addContactListener.unsubscribe();
      updateContactListener.unsubscribe();
      deleteContactListener.unsubscribe();

      addEventListener.unsubscribe();
      updateEventListener.unsubscribe();
      deleteEventListener.unsubscribe();

      addEventTypeListener.unsubscribe();
      updateEventTypeListener.unsubscribe();
      deleteEventTypeListener.unsubscribe();

      addVenueListener.unsubscribe();
      updateVenueListener.unsubscribe();
      deleteVenueListener.unsubscribe();

      addProducerListener.unsubscribe();
      updateProducerListener.unsubscribe();
      deleteProducerListener.unsubscribe();
    };
    // eslint-disable-next-line
  }, []);

  const setConsent = (value) => {
    dispatch({
      type: SET_CONSENT,
      payload: value,
    });
  };

  return (
    <EventContext.Provider
      value={{
        loading: state.loading,
        isAdmin: state.isAdmin,
        consent: state.consent,
        user: state.user,
        areaOfInterests: state.areaOfInterests,
        contacts: state.contacts,
        events: state.events,
        eventTypes: state.eventTypes,
        producers: state.producers,
        venues: state.venues,
        defaultVenue: state.venues.find((venue) => venue.name === 'Online'),
        setConsent,
      }}
    >
      <ConsentModal />
      <Spin spinning={state.loading} indicator={antIcon}>
        {props.children}
      </Spin>
    </EventContext.Provider>
  );
};

export default EventState;
