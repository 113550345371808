import React, { useState, useEffect } from 'react';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import EventForm from '../components/EventForm';
import MultiEventForm from '../components/MultiEventForm';
import parseISO from 'date-fns/parseISO';

const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!) {
    getEvent(id: $id) {
      id
      title
      summary
      description
      timeStart
      timeEnd
      cost
      audience
      price
      venues {
        items {
          id
          venue {
            id
            name
          }
        }
        nextToken
      }
      contact {
        id
        title
        firstName
        lastName
        email
        address
        telephone
      }
      contactId
      type
      faculty
      school
      faculty2
      school2
      faculty3
      school3
      siRecommends
      editors
      areaOfInterest
      moreInfo
      owner
      published
      image {
        bucket
        key
      }
    }
  }
`;

const EventPage = ({ mode, eventId }) => {
  const [eventDetail, setEventDetail] = useState({
    published: false,
    description: '',
    cost: 'free',
    audience: 'public',
  });

  function getImageSrc(image) {
    return (
      'https://' +
      image.bucket +
      '.s3-ap-southeast-2.amazonaws.com/public/' +
      image.key
    );
  }

  useEffect(() => {
    const getEventDetail = async (id) => {
      try {
        const input = { id };
        const res = await API.graphql(graphqlOperation(getEvent, input));
        const result = res.data.getEvent;
      // console.log('EVENT DETAIL', result)
        const venues = result.venues.items.map((item) => item.venue.id);
        setEventDetail({
          id: result.id,
          audience: result.audience,
          areaOfInterest: result.areaOfInterest,
          contact: result.contact ? result.contact.id : null,
          cost: result.cost,
          price: result.price,
          description: result.description,
          moreInfo: result.moreInfo,
          producer: result.school
            ? [result.faculty, result.school]
            : [result.faculty],
          producer2: result.school2
            ? [result.faculty2, result.school2]
            : [result.faculty2],
          producer3: result.school3
            ? [result.faculty3, result.school3]
            : [result.faculty3],
          published: result.published,
          summary: result.summary,
          'range-time-picker': [
            moment(parseISO(result.timeStart), 'YYYY-MM-DD HH:mm'),
            moment(parseISO(result.timeEnd), 'YYYY-MM-DD HH:mm'),
          ],
          title: result.title,
          type: result.type,
          venue: venues,
          image: getImageSrc(result.image),
          allVenues: result.venues.items,
          siRecommends: result.siRecommends,
          editors: result.editors,
        });
      } catch (err) {
        console.error('Error fetching event details', eventId);
      }
    };

    if (eventId) {
      getEventDetail(eventId);
    }
  }, [eventId]);

  return (
    <>
      {mode === 'add' && (
        <>
          <h1 style={{ textAlign: 'center' }}>Add New Event</h1>
          
          <p className='notes'>
            NOTE: If you don’t yet have your full event information but wish to
            save a draft, please complete all mandatory fields as best you can
            (using placeholder information as necessary) and leave the event as
            NOT be published under the “Publish” option at the end of the form.
          </p>
          <EventForm initialValues={eventDetail} mode={mode} />
        </>
      )}
      {mode === 'multi' && (
        <>
          <h1 style={{ textAlign: 'center' }}>Add Recurring Events</h1>
          <p className='notes'>
            NOTE: Use the ‘Add Recurring Event’ function for events which happen
            regularly but NOT daily (eg. weekly, monthly) on the same day/time.
            If your events happen daily (eg. Museum exhibitions, Seymour Centre
            shows) do NOT use this ‘Add Recurring Event’ function, but the ‘Add
            Single Event’ function. If you are unsure, use the Add Single Event
            function.
          </p>
          <p className='notes'>
            If you don’t yet have your full event information but wish to save a
            draft, please complete all mandatory fields as best you can (using
            placeholder information as necessary) and leave the event as NOT
            published under the “Publish” option at the end of the form.
          </p>
          <MultiEventForm initialValues={eventDetail} />
        </>
      )}
      {mode === 'edit' && (
        <>
          <h1 style={{ textAlign: 'center', paddingBottom: '1em' }}>
            Edit Event
          </h1>
          <EventForm
            eventId={eventId}
            initialValues={eventDetail}
            mode={mode}
          />
        </>
      )}
    </>
  );
};

export default EventPage;
