import { Flex } from '@aws-amplify/ui-react';
import { Button, DatePicker, notification, Select } from 'antd';
import { API, graphqlOperation } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react'


const DownloadCSV = () => {

  const exportQuery = /* GraphQL */ `
    query ListEvents (
      $filter: ModelEventFilterInput
      $limit: Int
      $nextToken: String
    ) {
      listEvents(filter: $filter, limit: $limit, nextToken: $nextToken) {
        items {
          id
          title
          timeStart
          timeEnd
          owner
          createdAt
          contact {
            id
            firstName
            email
            lastName
          }
          venues {
            items {
              id
              venue {
                id
                name
              }
            }
            nextToken
          }
        }
        nextToken
      }
    }`



  const [startDate, setStartDate] = useState(moment())
  const [endDate, setEndDate] = useState(moment().add(1, 'year'))

  const [loading, setLoading] = useState(false);

  const replacer = (key, value) => {
    return value === null ? '' : value // specify how you want to handle null values here
  }

  const handleExport = async () => {
    const queryFilter = {
      or: [
        {
          timeStart: { ge: startDate, le: endDate }
        },
        {
          and: {
            timeStart: { le: startDate },
            timeEnd: { le: endDate, ge: startDate },
          },
        }
      ]
    }

    setLoading(true);

    try {
      const firstResult = await API.graphql(graphqlOperation(exportQuery, {
        filter: queryFilter,
        limit: 100,
        nextToken: undefined
      }));
      let result = firstResult.data.listEvents.items;
      let nextToken = firstResult.data.listEvents.nextToken;
      while (nextToken) {
        const nextResults = await API.graphql(graphqlOperation(exportQuery, {
          filter: queryFilter,
          limit: 100,
          nextToken: nextToken,
        }))
        result = result.concat(nextResults.data.listEvents.items);
        nextToken = nextResults.data.listEvents.nextToken;
      }

      if (result.length <= 0) {
        notification.error({
          message: 'No events to export',
          duration: 4,
          description: "No events found"
        })
      } else {
        const data = await Promise.all(result.map(async item => {
          let eventVenue = item.venues.items.map(v => v.venue.name).join(' / ')
          return {
            "Event": item.title,
            "Event Date": moment(item.timeStart).format("DD-MM-YYYY"),
            "End date": moment(item.timeEnd).format("DD-MM-YYYY"),
            "Event Venue": eventVenue,
            "Contact": `${item.contact.firstName} ${item.contact.lastName}`,
            "Email": item.contact.email,
            "Created By": item.owner.replace(/USYDOKTA_+/, ''),
            "Created On": moment(item.createdAt).format("DD-MM-YYYY"),
          }
        }))

        // convert JSON to CSV
        const header = Object.keys(data[0])
        let csv = data.map(row => header.map(fieldName =>
          JSON.stringify(row[fieldName], replacer)).join(','))
        csv.unshift(header.join(','))
        csv = csv.join('\r\n')

        // Create link and download
        var link = document.createElement('a');
        link.setAttribute('href', 'data:text/csv;charset=utf-8,%EF%BB%BF' + encodeURIComponent(csv));
        const filename = `Active_Events_${moment(startDate).format("DD-MM-YYYY")}_to_${moment(endDate).format("DD-MM-YYYY")}.csv`
        link.setAttribute('download', filename);
        link.style.visibility = 'hidden';
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (err) {
      console.log(err)
    }

    setLoading(false);
  }


  return (
    <Flex style={{ marginRight: '1.2em' }}>
      <DatePicker
        placeholder='Select start date'
        value={startDate}
        onChange={(date, dateString) => setStartDate(date)}
      />
      <DatePicker
        placeholder='Select end date'
        value={endDate}
        onChange={(date, dateString) => setEndDate(date)}
      />
      <Button
        onClick={handleExport}
      >
        {loading ? "Loading..." : "Export Events"}
      </Button>
    </Flex>

  )
}

export default DownloadCSV